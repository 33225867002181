
import { Options } from "vue-class-component";
import ComponentMixins from "../ComponentMixins";

@Options({
    name: "flowable-common-form-components-sign-pannel",
    props: {
        // placeholder: {
        //     type: String,
        //     default: "请输入内容",
        // },
        // readerMode: {
        //     type: Boolean,
        //     default: false,
        // },
    },
})
export default class SignPannel extends ComponentMixins {}
